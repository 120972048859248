import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
// 3rd party
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner',
    props.title && 'section-inner',
    !props.title && 'pt-16 pb-80',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
    {...props}
    className={outerClasses}
  >
    <div className="container-sm">
      <div className={innerClasses}>
        <div className="hero-content">
          {props.title &&
            <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">{props.title}</span>
            </h3>
          }
          <div className="container-xs">
            {props.description &&
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                {props.description}
              </p>
            }
            <div className="reveal-from-bottom" data-reveal-delay="600">
              <ButtonGroup>
                {props.links.map((link, index) => (  
                  <Button key={index} tag="a" color="primary" wideMobile href={link.link}>
                    {link.icon &&
                    <FontAwesomeIcon 
                      size="lg"
                      icon={['fab', link.icon]}/>
                    }
                    {link.text}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
          <a
            data-video="https://player.vimeo.com/video/748819450"
            
            href="#0"
            aria-controls="video-modal"
            onClick={openModal}
          >
            <div className="overlay"
              width={896}
              height={504}>
              <Image
                src={require('./../../assets/images/play.webp')}
                alt="Play"
                width={80}
                height={80} />
            </div>
            <Image
              className="has-shadow"
              src={require('./../../assets/images/vid_img.webp')}
              alt="Video"
              width={896}
              height={504} />
          </a>
        </div>
        <Modal
          id="video-modal"
          show={videoModalActive}
          handleClose={closeModal}
          video="https://player.vimeo.com/video/748819450"
          videoTag="iframe" />
      </div>
    </div>
  </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;