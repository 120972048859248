
import React from 'react';
// import sections
import Hero from '../../components/sections/Hero';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Cta from '../../components/sections/Cta';
// Socials
import Instagram from '../../components/sections/Instagram';


const tiles = [
    {
      title: "Ateliers",
      content: [
        "J’organise ponctuellement des ateliers à Taden, près de Dinan. Les thèmes et recettes varient au fil des saisons, des demandes des participants et de mes envies.",
        "L’objectif de ces ateliers est de vous aider à intégrer une alimentation plus végétale en réalisant des recettes simples avec des produits locaux, bio et de saison.",
        "Lorsque j’organise un atelier, je partage toutes les informations (date, heure, thème) sur Facebook et Instagram. N’hésitez pas à vous abonner pour être tenu(e) informé(e) !",
        "Si vous souhaitez organiser un atelier entre amis, entre collègues ou en famille, je peux également vous organiser un atelier privé, à Taden ou chez vous.",
      ],
      images: [
        {source: "images/tiles/IMG_300.jpg"},
        {source: "images/tiles/IMG_1976.jpg"},
        {source: "images/tiles/IMG_3156.jpg"}
      ],
      imagesConfig: {
        width:'100%',
        height: ['350px','350px'],
        layout: [2,1],
        showMore: false
      },
      links: [{
        icon: 'instagram',
        link: "https://www.instagram.com/margothamonic_traiteur"
      },{
        icon: 'facebook',
        link: "https://www.facebook.com/Margothamonic.cuisinevegetarienne"
      },{
        text: "Contactez-moi !",
        link: "https://forms.office.com/r/6iKriq4xwx"
      }]
    },
  ]

const Activities = () => {

    return (
      <>
        <Hero hasBgColor className="illustration-section-01" 
          title1="Margot"
          title2="Hamonic"
          description1="Traiteur & Cheffe privée."
          description2="Cuisine végétarienne, de saison, majoritairement bio et locale."
        />
        <FeaturesSplit tiles={tiles} className="illustration-section-02" />
        <Instagram hasBgColor/>
        <Cta split/>
      </>
    );
  }
  
  export default Activities;