import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import Cta from '../components/sections/Cta';
// Sections
import Image from '../components/elements/Image'
// Socials
import Instagram from '../components/sections/Instagram';


const About = () => {

  return (
    <>
      <Hero hasBgColor className="illustration-section-01" 
        title1="Margot"
        title2="Hamonic"
        description1="Traiteur & Cheffe privée."
        description2="Cuisine végétarienne, de saison, majoritairement bio et locale."
      />
      <div className="features-split-inner section-inner"> 
        <div className="container">
          <div className="has-top-divider has-bottom-divider split-wrap">
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                A Propos
                </h3>
                <h2 className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Mon parcours
                </h2>
                <p className="m-0">
                Après un début de carrière entre les Ressources Humaines et l'informatique, c’est lors de mon séjour à Vancouver que je décide de me tourner vers la cuisine, et plus spécifiquement la cuisine végétarienne.  
                J’ai toujours été baignée dans le monde de la cuisine mais la formation Plant-based certification de Rouxbe m’a aidée à acquérir les bases de la cuisine végétarienne.  
                </p>
                <h2 className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Ma passion
                </h2>
                <p className="m-0">
                Après une première expérience en cuisine chez Annadata puis à la ferme La Pampa, je décide de lancer mes activités de Traiteur et Cheffe privée. J’organise également des ateliers près de Dinan.  
                </p>
                <p className="m-0">
                Ma cuisine est faite de produits bruts, de légumes de saison, et est au maximum biologique et locale. Je travaille principalement avec les producteurs bio de ma région.  
                </p>
                <h2 className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Mon projet
                </h2>
                <p className="m-0">
                Passionnée, je cherche constamment à innover, découvrir et faire découvrir de nouvelles saveurs.  
                </p>
                <p className="m-0">
                Je cuisine beaucoup à l’instinct; mes plats sont donc variés et évoluent beaucoup, notamment au fil des saisons, de la disponibilité des produits et de mes envies.
                </p>
              </div>
              <div className={'split-item-image center-content-mobile reveal-from-bottom'}
                data-reveal-container=".split-item">
                <Image
                  src={require("../assets/images/about.jpg")}
                  alt={"Example ALT"}
                  width={250}
                  height={300}
                  rounded />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Instagram hasBgColor/>
      <Cta hasBgColor split />
    </>
  );
}

export default About;