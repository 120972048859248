import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import About from './views/About';
import Activity1 from './views/Activities/activity1';
import Activity2 from './views/Activities/activity2';
import Activity3 from './views/Activities/activity3';
import Privacy from './views/Privacy';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

ReactGA.timing({
  category: 'JS Libraries',
  variable: 'load',
  value: 20, // in milliseconds
  label: 'CDN libs'
});

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    console.log(page)
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/a-propos" component={About} layout={LayoutDefault} />
          <AppRoute exact path="/traiteur" component={Activity1} layout={LayoutDefault} />
          <AppRoute exact path="/cheffe-privee" component={Activity2} layout={LayoutDefault} />
          <AppRoute exact path="/ateliers" component={Activity3} layout={LayoutDefault} />
          <AppRoute exact path="/privacy" component={Privacy} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;