
import React from 'react';
// import sections
import Hero from '../../components/sections/Hero';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Cta from '../../components/sections/Cta';
import VideoSection from '../../components/sections/VideoSection';

const tiles = [
  {
    title: "Cheffe privée",
    content: [
      "Vous organisez un séjour de plusieurs jours (séminaire d'entreprise, retraite de yoga..) et cherchez une cheffe privée?",
      "Je m'occupe de tous vos repas et de la logistique, du petit déjeuner au dîner.",
      "Je me déplace hors Bretagne pour ce type d'événement, n'hésitez pas à me contacter pour en discuter."
    ],
    // list: {
    //   text: "Pour moi, la notion de cheffe à domicile comprend deux aspects :",
    //   items: [
    //     "Cheffe à domicile pour un événement : soirée, anniversaire, anniversaire de mariage, etc. Cela rejoint la notion de traiteur.",
    //     "Cheffe à domicile pour une longue durée : sur plusieurs jours, je vis sur place et m’occupe des repas (petit déjeuner, déjeuner, etc.). Cela peut être pour un événement privé (vacances, réunion familiale), professionnel (séminaire, voyage d’affaire, etc.) ou public (retraites, bien être, etc.). Je me déplace dans toute la France, et même à l’étranger ! "
    //   ],
    // },
    images: [
      {source: "images/tiles/IMG_4531.jpg"},
      {source: "images/tiles/IMG_1791.jpg"},
      {source: "images/tiles/tile2.jpg"},
      {source: "images/tiles/IMG_4460.jpg"},
    ],
    imagesConfig: {
      width:'100%',
      height: ['250px','250px'],
      layout: [2,2],
      showMore: false
    },
    links: [{
      icon: 'instagram',
      link: "https://www.instagram.com/margothamonic_traiteur"
    },{
      icon: 'facebook',
      link: "https://www.facebook.com/Margothamonic.cuisinevegetarienne"
    },{
      text: "Contactez-moi !",
      link: "https://forms.office.com/r/6iKriq4xwx"
    }]
  }
]

const videoLinks = [{
  icon: 'instagram',
  link: "https://www.instagram.com/margothamonic_traiteur"
},{
  icon: 'facebook',
  link: "https://www.facebook.com/Margothamonic.cuisinevegetarienne"
},{
  text: "Contactez-moi !",
  link: "https://forms.office.com/r/6iKriq4xwx"
}]


const Activities = () => {

    return (
      <>
        <Hero hasBgColor className="illustration-section-01" 
          title1="Margot"
          title2="Hamonic"
          description1="Traiteur & Cheffe privée."
          description2="Cuisine végétarienne, de saison, majoritairement bio et locale."
        />
        <FeaturesSplit tiles={tiles} className="illustration-section-02" />
        <VideoSection 
          title="Traiteur, Cheffe privée."
          description="Decouvrez mes autres créations et activités sur Facebook & Instagram, ou Contactez-moi  ci dessous!"
          links={videoLinks}
          hasBgColor/>
        <Cta/>
      </>
    );
  }
  
  export default Activities;