import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import FooterSocial from '../layout/partials/FooterSocial';
// 3rd party
import { ReactPhotoCollage } from "react-photo-collage";
import { isMobile } from "react-device-detect";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  landingImage,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    landingImage && 'primary-section',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      {landingImage != null &&
      <>
        <div className="landingImage">
          <ReactPhotoCollage
            width={'100%'}
            height={isMobile ? ['60vh'] : ['80vh']}
            layout={landingImage.layout}
            photos={landingImage.images}
            showNumOfRemainingPhotos={false}
          />
        </div>
        <div className="overImage">
          <div className="container">  
            <div className={innerClasses}>
              <div className="hero-content">
                <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                  {props.title1} <span className="text-color-primary">{props.title2}</span>
                </h1>
                <p className="m-0 mb-8 reveal-from-bottom" data-reveal-delay="400">
                  {props.description1}
                  </p>
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  {props.description2}
                  </p>
                <div className="reveal-from-bottom" data-reveal-delay="600">
                  <ButtonGroup>
                    <FooterSocial />
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      }

      {!landingImage && 
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              {props.title1} <span className="text-color-primary">{props.title2}</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-8 reveal-from-bottom" data-reveal-delay="400">
                {props.description1}
                </p>
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                {props.description2}
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <FooterSocial />
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;