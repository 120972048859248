import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import ButtonGroup from '../elements/ButtonGroup';
// 3rd party
import { isMobile } from "react-device-detect";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    // split && 'cta-split'
  );  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container section-inner">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="invert-color m-0">
              Contactez-moi !
              </h3>
            <p className="invert-color m-0">Pour toute demande de devis, n'hésitez pas à m'envoyer un e-mail (<a href='mailto:margothamonic@gmail.com'>margothamonic@gmail.com</a>) ou à m'appeler (<a href='tel:+33624853249'>06 24 85 32 49</a>). Vous pouvez également me contacter via le formulaire ci-dessous, je vous recontacterai au plus vite. Pour information, je suis basée à Taden (22100).
              </p>
          </div>
          <div className="cta-action">   
            <ButtonGroup>
              <Button tag="a" color="light" wideMobile href='mailto:margothamonic@gmail.com'>
              {!isMobile && 
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <title>E-mail</title>
                  <path
                    d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z"/>
                </svg>
                }
                {isMobile && 
                  'E-mail'
                }
              </Button>
              <Button tag="a" color="light" wideMobile href='tel:+33624853249'>
                {!isMobile && 
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 54 54"
                    xmlns="http://www.w3.org/2000/svg">
                    <title>Téléphone</title>
                    <path xmlns="http://www.w3.org/2000/svg" d="M53.364,40.908c-2.008-3.796-8.981-7.912-9.288-8.092c-0.896-0.51-1.831-0.78-2.706-0.78c-1.301,0-2.366,0.596-3.011,1.68  c-1.02,1.22-2.285,2.646-2.592,2.867c-2.376,1.612-4.236,1.429-6.294-0.629L17.987,24.467c-2.045-2.045-2.233-3.928-0.632-6.291  c0.224-0.309,1.65-1.575,2.87-2.596c0.778-0.463,1.312-1.151,1.546-1.995c0.311-1.123,0.082-2.444-0.652-3.731  c-0.173-0.296-4.291-7.27-8.085-9.277c-0.708-0.375-1.506-0.573-2.306-0.573c-1.318,0-2.558,0.514-3.49,1.445L4.7,3.986  c-4.014,4.013-5.467,8.562-4.321,13.52c0.956,4.132,3.742,8.529,8.282,13.068l14.705,14.705c5.746,5.746,11.224,8.66,16.282,8.66  c0,0,0,0,0.001,0c3.72,0,7.188-1.581,10.305-4.698l2.537-2.537C54.033,45.163,54.383,42.833,53.364,40.908z"/>
                  </svg>
                }
                {isMobile && 
                  'Téléphone'
                }
              </Button>
              <Button tag="a" color="light" wideMobile href='https://forms.office.com/r/6iKriq4xwx'>
                Formulaire
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;