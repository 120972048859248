import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";

const tiles = [
  {
    title: "Politique de Confidentialité",
    list: {
      items: [
        "Bienvenue sur https://margothamonic.com/. Ce site web est exploité par [Votre Nom ou le Nom de votre Entreprise]. Nous respectons votre vie privée et nous nous engageons à la protéger.",
        "Cette Politique de Confidentialité explique comment nous collectons, utilisons et partageons vos informations personnelles lorsque vous visitez ou utilisez ce site web.",
      ],
    },
    image: "tile1",
  },
  {
    title: "Collecte des Informations",
    list: {
        text:       "La seule information que nous collectons est celle de Google Analytics pour suivre l'utilisation du site web.",
      items: [
        "Nous utilisons Google Analytics pour comprendre comment nos visiteurs utilisent notre site web. Google Analytics recueille des informations sur les pages que vous visitez, le temps que vous passez sur le site, comment vous êtes arrivé sur le site et sur quoi vous cliquez.",
        "Pour plus d'informations sur la manière dont Google utilise vos données, veuillez visiter https://www.google.com/policies/privacy/partners/.",
      ],
    },
    image: "tile2",
  },
  {
    title: "Vos Droits, & Modifications de la Politique de Confidentialité",
    list: {
      items: [
        "Conformément à la loi, vous avez le droit de demander l'accès, la rectification, la suppression de vos données, ainsi que le droit de limiter et d'opposer le traitement de vos données.",
        "Nous pouvons mettre à jour cette politique de confidentialité de temps en temps afin de refléter, par exemple, des changements dans nos pratiques ou pour d'autres raisons opérationnelles, légales ou réglementaires.",
      ],
    },
    image: "tile3",
  },
  {
    title: "Contactez-nous",
    content:
      "Pour plus d'informations sur nos pratiques de confidentialité, si vous avez des questions, ou si vous souhaitez exercer vos droits en matière de protection des données, veuillez nous contacter via les coordonnées fournies sur notre site web.",

    image: "tile4",
    link: {
      url: "#contact",
      text: "En savoir plus",
    },
  },
];

const Privacy = () => {
  return (
    <>
      <Hero
        hasBgColor
        className="illustration-section-01"
        title1="Margot"
        title2="Hamonic"
        description1="Traiteur & Cheffe privée."
        description2="Cuisine végétarienne, de saison, majoritairement bio et locale."
      />
      <FeaturesSplit
        tiles={tiles}
        invertMobile
        className="illustration-section-02"
      />
      <Testimonial hasBgColor />
      <Cta id="contact" hasBgColor />
    </>
  );
};

export default Privacy;
