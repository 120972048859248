
import React from 'react';
// import sections
import Hero from '../../components/sections/Hero';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial from '../../components/sections/Testimonial';
import Cta from '../../components/sections/Cta';

const tiles = [
  {
    title: "Traiteur événementiel",
    content: [
      "Mes prestations sont créées sur mesure, n’hésitez pas à me contacter afin de discuter ensemble de votre prochain événement de minimum 10 personnes.",
      "Je me déplace en Bretagne dans un rayon de 2h autour de Dinan."
    ],
    deeplist: {
      text: "Pour vous donner une première idée, voici des exemples d’événements pour lesquels je propose mes services, à partir de 10 convives :",
      items: [
        {
          title: "Particuliers",
          items: [
            "Mariages",
            "Brunch de lendemain de mariage",
            "Événements particuliers : anniversaires, anniversaires de mariage, réunions familiales."
          ]
        },
        {
          title: "Professionnels",
          items: [
            "Inaugurations",
            "Séminaires",
          ]
        },
        {
          title: "Événements publics",
          items: [
            "Stands en festival"
          ]
        },
      ],
    },
    list: {
      text: "Voici quelques exemples de prestations proposées :",
      items: [
        "Formule classique : apéritif, entrée, plat, dessert ; service à table ou buffet",
        "Service en plats à partager : les plats sont directement placés à table",
        "Buffet déjeunatoire/dinatoire : plusieurs plats sont dressés au fur et à mesure, du salé au sucré",
        "Livraison sur le lieu de votre événement"
      ],
    },
    images: [
      {source: "images/tiles/IMG_3235.jpg"},
      {source: "images/tiles/IMG_1963.jpg"},
      {source: "images/tiles/IMG_4525.jpg"}
    ],
    imagesConfig: {
      width: '100%',
      height: ['350px','350px','350px'],
      layout: [1,1,1],
      showMore: false
    },
    links: [{
      icon: 'instagram',
      link: "https://www.instagram.com/margothamonic_traiteur"
    },{
      icon: 'facebook',
      link: "https://www.facebook.com/Margothamonic.cuisinevegetarienne"
    },{
      text: "Contactez-moi !",
      link: "https://forms.office.com/r/6iKriq4xwx"
    }]
  }
]
const Activities = () => {

    return (
      <>
        <Hero hasBgColor className="illustration-section-01" 
          title1="Margot"
          title2="Hamonic"
          description1="Traiteur & Cheffe privée."
          description2="Cuisine végétarienne, de saison, majoritairement bio et locale."
        />
        <FeaturesSplit tiles={tiles} className="illustration-section-02" />
        <Testimonial hasBgColor />
        <Cta hasBgColor/>
      </>
    );
  }
  
  export default Activities;