import React from 'react';
// import axios from 'axios';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Avis Clients',
    paragraph: 'Quelques avis de clients déjà convaincus!'
  };

  // const ratings = axios.get('https://graph.facebook.com/v2.7/101013415741020/ratings&accessToken=EAAK8uyZAI3doBAGH9XxUX5VqTwyG5jQvprNZBVNAwjxjiSy1K9piRewzNVnjEeKncB5ZAYP5x0sxjU46vYOZACNYtn9FHyPLHbSAg1Hads7lDVSSBvWtV1l7ny1HlfOB1pDodTvs7hXX1IJe0gsI7AkUtRup0UppFAeoZBWPRgUVRuXlVMvIRYkoQJQSER1E5n7L83qMqOwZDZD')
  // .then(response => {
  //   console.log(response)
  // })

  const testimonials = [
    {
      review: `
      Margot a géré notre vin d'honneur pour 160 personnes. Nous nous sommes régalé. 
      Cuisine variée, colorée, et de saison. 
      Très belle découverte.
      `,
      author: "L. Rocaboy"
    },{
      review: `
      Cuisine originale et créative digne de top chefs sans aucun doute, maîtrisé parfaite des goûts, des saveurs et des cuissons, nous avons passer une superbe soirée plein de sympathie et de convivialité 
      Nous recommandons vivement et à renouveler très vite.
      `,
      author: "A. golfy"
    },{
      review: `
      Un beau moment de partage gourmand dans la convivialité, la bienveillance lors d'un atelier sur le thème de l'apéritif végétal. Recettes très créatives, variées, appétissantes, j'ai hâte pour la suite. Merci Margot pour ce fabuleux voyage culinaire.
      `,
      author: "G. Rey"
    },
    // {
    //   review: ``
    //   author: "G. Rey",
    //   company: {
    //     name: "",
    //     link: "",
    //   }
    // }
  ]

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {testimonials.map(testimonial => (
              <div key={testimonial.author} className="tiles-item reveal-from-right" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="invert-color text-sm mb-0">
                      {testimonial.review}
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-secondary">{testimonial.author}</span>
                    {testimonial.company &&
                      <span>
                        <span className="text-color-low"> / </span>
                        <span className="testimonial-item-link">
                          <a href={testimonial.company.link}>{testimonial.company.name}</a>
                        </span>
                      </span>
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;