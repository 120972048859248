import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import FooterSocial from '../layout/partials/FooterSocial';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
// 3rd party
import { ReactPhotoCollage } from "react-photo-collage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );
  
  return (
    <section
      {...props}
      className={outerClasses}
    >      
    <div className="features-split-inner section-inner"> 
      <div className="container has-top-divider has-bottom-divider">
        <div className={innerClasses}>
          <div className={splitClasses}>
            
            {props.tiles.map((tile, tile_index) => (

                <div key={tile_index} className="split-item">
                  <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                      {tile.description}
                      </div>
                    
                    {tile.title &&
                      <h3 className="mt-0 mb-12">
                      {tile.title}
                        </h3>
                    }

                    {tile.content && typeof tile.content == "string" &&
                      <p className="m-0">
                        {tile.content}
                        </p>
                    }

                    {tile.content && typeof tile.content == "object" && tile.content.map((content, content_index) => (  
                      <p key={content_index} className="m-0">  
                        {content}
                        <br/><br/>
                      </p>
                    ))}

                    {tile.list &&
                      <p className="mb-8">
                        {tile.list.text}
                      </p>
                    }

                    {tile.list &&
                      <div>
                        <p>
                          {tile.list.title}
                        </p>
                        <ul> 
                          {tile.list.items.map((item, item_index) => (  
                            <li key={item_index} className='mb-8'>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    }

                    {tile.deeplist &&
                      <div>
                        <p>
                          {tile.deeplist.text}
                        </p>
                        {tile.deeplist.items.map((list, list_index) => ( 
                        <ul key={list_index}> 
                          <li className="mb-8 text-color-primary fw-600">
                            {list.title}
                          </li>   
                          <ul>             
                          {list.items.map((item, item_index) => (  
                            <li key={item_index} className='ml-16'>
                              {item}
                            </li>
                          ))}
                          </ul>
                        </ul>
                        ))}
                      </div>
                    }

                    {tile.footer && typeof tile.footer == "string" &&
                      <p className="m-0">
                        {tile.footer}
                        </p>
                    }

                    {tile.social &&
                      <FooterSocial />
                    }

                    {tile.link && 
                      <a href={tile.link.url} className="mt-24 button button-primary button-sm">{tile.link.text}</a>
                    }   

                    {tile.links &&
                      <ButtonGroup className='mt-24'>
                        {tile.links.map((link, link_index) => (  
                          <Button key={link_index} tag="a" color="primary" wideMobile href={link.link}>
                            {link.icon &&
                            <FontAwesomeIcon 
                              size="lg"
                              icon={['fab', link.icon]}/>
                            }
                            {link.text}
                          </Button>
                        ))}
                      </ButtonGroup>
                    }

                  </div>
                  <div className={
                    classNames(
                      'split-item-image center-content-mobile reveal-from-bottom'
                      // imageFill && 'split-item-image-fill'
                    )}
                    data-reveal-container=".split-item">
                      {tile.images &&
                        <ReactPhotoCollage
                          width={tile.imagesConfig.width}
                          height={tile.imagesConfig.height}
                          layout={tile.imagesConfig.layout}
                          photos={tile.images}
                          showNumOfRemainingPhotos={tile.imagesConfig.showMore}
                        />
                      }
                      {!tile.images && 
                        <Image
                          src={require("./../../assets/images/tiles/" + tile.image + ".jpg")}
                          alt={tile.image}
                          width={200}
                          height={200} 
                          square 
                          rounded />
                      }
                  </div>
                </div>    
            ))}

          </div>
        </div>
      </div>
    </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;