import React, { useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

// Custom import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactPhotoCollage } from "react-photo-collage";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const posts = [
  {
    link: "https://www.instagram.com/p/C3aMuMUMkuR/",
    image: "images/instagram/C3aMuMUMkuR.jpg",
  },
  {
    link: "https://www.instagram.com/p/C0W0I_FMMUl/",
    image: "images/instagram/C0W0I_FMMUl.jpg",
  },
  {
    link: "https://www.instagram.com/p/CxATTkPsi4N/",
    image: "images/instagram/CxATTkPsi4N.jpg",
  },
  {
    link: "https://www.instagram.com/p/Css8H0cMRHU/",
    image: "images/instagram/Css8H0cMRHU.jpg",
  },
  {
    link: "https://www.instagram.com/p/CpnA2O_M69z/",
    image: "images/instagram/CpnA2O_M69z.jpg",
  },
  {
    link: "https://www.instagram.com/p/CqU8KM0M5gK/",
    image: "images/instagram/CqU8KM0M5gK.jpg",
  },
  {
    link: "https://www.instagram.com/p/CtW2VWdsjR4/",
    image: "images/instagram/CtW2VWdsjR4.jpg",
  },
  {
    link: "https://www.instagram.com/p/C2ZKIkEsMYS/",
    image: "images/instagram/C2ZKIkEsMYS.jpg",
  },
  {
    link: "https://www.instagram.com/p/CqSQQWYMRQl/",
    image: "images/instagram/CqSQQWYMRQl.jpg",
  },
];

const Instagram = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "Instagram section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "Instagram-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Galerie",
    paragraph:
      "Suivez-moi sur Facebook & Instagram pour (re)découvrir mes dernières creations !",
  };

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const Post = ({ post, size }) => {
    const [isShown, setIsShown] = useState(false);
    console.log(post);
    const settings = {
      width: `${size}px`,
      height: [`${size}px`],
      photos: [{ source: post.image }],
      layout: [1],
    };
    return (
      <a
        style={{ position: "relative" }}
        href={post.link}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        target="_blank"
        rel="noreferrer" // security
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: 99,
            opacity: isShown ? 1 : 0,
            transition: "opacity 1s",
          }}
        >
          <FontAwesomeIcon size="6x" icon={["fab", "instagram"]} />
          <p>Continuer sur Instagram</p>
        </div>
        <ReactPhotoCollage {...settings} />
      </a>
    );
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {posts.map((post) => (
              <Post post={post} size={300} key={post.link} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

Instagram.propTypes = propTypes;
Instagram.defaultProps = defaultProps;

export default Instagram;
