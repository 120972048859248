import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Instagram from '../components/sections/Instagram';
import VideoSection from '../components/sections/VideoSection';

const tiles = [
  {
    title: "Traiteur événementiel",
    content: "La cuisine joue un rôle majeur dans la réussite d’un événement. Ma cuisine végétale, parfumée et colorée, contribuera à cette réussite, ainsi qu’ aux souvenirs de ces moments. Je peux me charger de vos événements privés (mariages, anniversaires, soirées) et professionnels (soirées d’entreprise, inaugurations...).",
    image: "tile1",
    link: {
      url: 'http://margothamonic.com/traiteur',
      text: "En savoir plus",
    }
  },
  // {
  //   title: "Cheffe à domicile",
  //   list: {
  //     text: "À partir de 6 personnes, je cuisine directement chez vous, comme si vous étiez au restaurant mais avec plus d’intimité. Pour ce faire :",
  //     items: [
  //       "nous discutons ensemble de vos attentes",
  //       "je vous propose un menu",
  //       "je prends en charge les courses",
  //       "je m’occupe de la mise en place (dans mon labo) et",
  //       "je finalise les dernières préparations et cuissons (dans votre cuisine)."
  //     ],
  //   },
  //   footer: "Tout ce dont vous avez à faire, c’est de profiter de ce moment avec vos proches. Je repars et la cuisine est aussi propre qu’avant !",
  //   image: "tile3",
  //   link: {
  //     url: 'https://margothamonic.com/cheffe-a-domicile',
  //     text: "En savoir plus",
  //   },
  // },
  // {
  //   title: "Ateliers",
  //   content: "J’organise ponctuellement des ateliers de cuisine à Taden, près de Dinan. Nous cuisinons ensemble des recettes végétariennes de saison. Ce sont de vrais moment de partage, de rencontres et d’échanges. Afin d’être tenu(e) informé(e) des prochains ateliers, je vous invite à rejoindre ma page Facebook.",
  //   image: "tile4",
  //   links: [{
  //     icon: 'facebook',
  //     link: "https://www.facebook.com/Margothamonic.cuisinevegetarienne"
  //   },{
  //     url: 'https://margothamonic.com/ateliers',
  //     text: "En savoir plus",
  //   }]
  // },
  {
    title: "Week-end, retraites, séminaires, séjours longues durées",
    content: "Je me déplace et cuisine pour vous, du petit-déjeuner au dîner, pour vos événements privés ou professionnels : week-end bien-être, retraites, séminaires,… Je m’adapte à la durée de votre séjour.",
    image: "tile2",
    links: [{
      text: "Contactez-moi !",
      link: "https://forms.office.com/r/6iKriq4xwx"
    }]
  },
]

const videoLinks = [{
  icon: 'instagram',
  link: "https://www.instagram.com/margothamonic_traiteur"
},{
  icon: 'facebook',
  link: "https://www.facebook.com/Margothamonic.cuisinevegetarienne"
},{
  text: "Contactez-moi !",
  link: "https://forms.office.com/r/6iKriq4xwx"
}]

const landing = {
  images:[
    {source: "images/tiles/IMG_3235.jpg"},
    {source: "images/tiles/IMG_1963.jpg"},
    {source: "images/tiles/IMG_4525.jpg"},
    {source: "images/tiles/IMG_4531.jpg"},
    {source: "images/tiles/IMG_1791.jpg"},
  ],
  layout:[1]
}

const Home = () => {

  return (
    <>
      <Hero hasBgColor className="illustration-section-01" 
        title1="Margot"
        title2="Hamonic"
        description1="Traiteur & Cheffe privée."
        description2="Cuisine végétarienne, de saison, majoritairement bio et locale."
        landingImage={landing}
      />
      <VideoSection 
        description="Découvrez mes autres créations et activités sur Facebook & Instagram, ou contactez-moi ci-dessous !"
        links={videoLinks}
        hasBgColor/>
      <FeaturesSplit tiles={tiles} invertMobile className="illustration-section-02" />
      <Instagram hasBgColor/>
      <Testimonial />
      <Cta split/>
    </>
  );
}



export default Home;